<template>
  <div id="infoList">
    <div style="background:#f5f5f5;" class="w100">
      <div class="breadNav">
        <div class="nav_info flex">
          您的位置：
          <a class="active" @click="goHome">首页</a> > 资讯
        </div>
      </div>
      <div class="infoContent flex">
        <div class="leftInfoBox">
          <p>孔雀资讯</p>
          <div class="container">
            <div class="list row">
              <!-- <img src="" alt=""> -->
              <div class="img"></div>
              <div class="listInfo">
                <p class="title" @click="goDetailPage">标题标题标题标题标题标题标题标题标题标题标题标题</p>
                <p class="desc f14">
                  此处为新闻简介此处为新闻简介此处为新闻简介此处为新闻简介此处为新闻简介此处为新
                  此处为新闻简介此处为新闻简介此处为新闻简介此处为新闻简介闻简介此处为新闻简介
                </p>
                <p class="f12 gray">
                  <span class="time">2019-04-19</span>
                  <img src alt>
                  <span>2428人浏览</span>
                </p>
              </div>
            </div>
            <div class="list row">
              <!-- <img src="" alt=""> -->
              <div class="img"></div>
              <div class="listInfo">
                <p class="title" @click="goDetailPage">标题标题标题标题标题标题标题标题标题标题标题标题</p>
                <p class="desc f14">
                  此处为新闻简介此处为新闻简介此处为新闻简介此处为新闻简介此处为新闻简介此处为新
                  此处为新闻简介此处为新闻简介此处为新闻简介此处为新闻简介闻简介此处为新闻简介
                </p>
                <p class="f12 gray">
                  <span class="time">2019-04-19</span>
                  <img src alt>
                  <span>2428人浏览</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="rightInfoBox">
          <p>商城公告</p>
          <Info></Info>
        </div>
      </div>
      <div class="pagination">
        <Pagination></Pagination>
      </div>
    </div>
  </div>
</template>
<script>
import Pagination from "@/components/common/pagination";
import Info from "@/components/common/info";
export default {
  components: { Pagination, Info },
  data() {
    return {};
  },
  methods: {
    goHome() {
      this.$router.push({
        path: "/"
      });
    },
     goDetailPage() {
      this.$router.push({
        path: "/infoDetail"
      });
    },
    

  }
};
</script>
<style lang="scss" scoped>
@import '../../assets/scss/change.scss';
#infoList {
  margin-top: 250px;
}
.active {
  color: #0db168;
  cursor: pointer;
}
a{
    cursor: pointer;
}
.more {
  cursor: pointer;
  text-align: center;
}
.infoContent {
  width: 1200px;
  margin: 0 auto;
  min-height: 500px;
  margin-top: 20px;
}
.leftInfoBox {
  flex: 1;
  margin-right: 20px;
  > p {
    margin-bottom: 15px;
  }
  .container {
    padding: 0 20px 20px;
    margin-bottom: 30px;
    background: #fff;
    .list {
      border-bottom: 1px solid #ddd;
      padding: 20px 0;
      &:last-child {
        border-bottom: none;
      }
      .img {
        width: 300px;
        height: 120px;
        margin-right: 15px;
        background: #888;
      }
      .listInfo {
        .title {
          font-weight: bold;
          padding: 0px 0 20px;
          &:hover {
            color: #0db168;
            cursor: pointer;
          }
        }
        .desc {
          color: #888;
          line-height: 20px;
        }
        .time {
          margin-right: 30px;
        }
        p {
          padding-top: 13px;
        }
      }
    }
  }
}
.rightInfoBox {
  width: 300px;
  > p {
    margin-bottom: 15px;
  }
}

.pagination {
  padding-bottom: 40px;
  text-align: center
}
</style>


