<template>
  <div id="info">
    <div class="container">
      <div class="list">
        <div class="name">商城公告标题商城公告标题</div>
        <p class="f14">
          商城公告内容商城公告内容商城公告内容商城公告内容商城公告内容商城公告内容
          商城公告内容商城公告内容商城公告内容商城公告内容商城公告内容
        </p>
        <p>2019-04-19</p>
      </div>
      <div class="list">
        <div class="name">商城公告标题商城公告标题</div>
        <p class="f14">
          商城公告内容商城公告内容商城公告内容商城公告内容商城公告内容商城公告内容
          商城公告内容商城公告内容商城公告内容商城公告内容商城公告内容
        </p>
        <p>2019-04-19</p>
      </div>
      <div class="list">
        <div class="name">商城公告标题商城公告标题</div>
        <p class="f14">
          商城公告内容商城公告内容商城公告内容商城公告内容商城公告内容商城公告内容
          商城公告内容商城公告内容商城公告内容商城公告内容商城公告内容
        </p>
        <p>2019-04-19</p>
      </div>
      <div class="list">
        <div class="name">商城公告标题商城公告标题</div>
        <p class="f14">
          商城公告内容商城公告内容商城公告内容商城公告内容商城公告内容商城公告内容
          商城公告内容商城公告内容商城公告内容商城公告内容商城公告内容
        </p>
        <p>2019-04-19</p>
      </div>
      <div class="list more" @click="goDetailPage">更多公告 ></div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {
    // 跳转到详情
    goDetailPage() {
      this.$router.push({
        path: "/infoDetail"
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.container {
  padding: 0 20px 20px;
  margin-bottom: 30px;
  background: #fff;
  .list {
    border-bottom: 1px solid #ddd;
    padding: 20px 0;
    &:last-child {
      border-bottom: none;
    }
    .name {
      padding: 10px 0;
    }
    p {
      line-height: 20px;
      font-size: 14px;
      color: #888;
      &:last-child {
        padding-top: 10px;
      }
    }
  }
}
</style>


